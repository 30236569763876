import * as React from "react";

import { UmbrellaMenu } from "./UmbrellaMenu";
import { UserMenu } from "./UserMenu";

const AccountUser: React.FC = (): JSX.Element => {
  return (
    <div className="flex items-center rounded-[8px] border border-cream-300 h-[98px]">
      <UmbrellaMenu />
      <UserMenu />
    </div>
  );
};

export default AccountUser;
