import { Menu, Transition } from "@headlessui/react";
import ArrowIcon from "@material-design-icons/svg/filled/keyboard_arrow_down.svg";
import SchoolIcon from "@material-design-icons/svg/outlined/school.svg";
import classnames from "classnames";
import * as React from "react";

import { useRequiredAuthContext } from "~/components/auth/common";
import Spinner from "~/components/core/Spinner/Index";
import { MINUTES_IN_HOUR } from "~/constants/dates";
import {
  UMBRELLA_BILLING_MODE,
  UmbrellaAccount
} from "~/declarations/models/UmbrellaAccount";
import { useAuthenticatedFetch } from "~/utils/http";
import { trackEvent } from "~/utils/segment";

import ParentSchoolIcon from "../../../../../assets/icons/parentSchool.svg";
import {
  menuButtonClasses,
  menuItemClasses,
  menuItemsClasses,
  menuTransitionClasses
} from "./UserMenu";

export const UmbrellaMenu: React.FC = () => {
  const { user } = useRequiredAuthContext();
  const { selectedUmbrella, onSelectUmbrella } = useRequiredAuthContext();

  const handleClickSelectItem = (umbrellaAccount: UmbrellaAccount) => {
    onSelectUmbrella?.(umbrellaAccount);
    trackEvent("change_umbrella_account", {
      umbrella: umbrellaAccount.umbrellaAccName
    });
  };

  const handleKeyPressSelectItem = (
    umbrellaAccount: UmbrellaAccount,
    e?: React.KeyboardEvent
  ) => {
    if (e?.key === "Enter" || e?.key === "Space") {
      onSelectUmbrella?.(umbrellaAccount);
      trackEvent("change_umbrella_account", {
        umbrella: umbrellaAccount.umbrellaAccName
      });
    }
  };

  if (user?.umbrellaAccounts?.length === 1) {
    return (
      <div>
        {selectedUmbrella.displayName ?? selectedUmbrella.umbrellaAccName}
      </div>
    );
  }

  return (
    <Menu as="div" className="relative h-full w-[284px]">
      <MenuButton selectedUmbrella={selectedUmbrella} />

      <Transition as={React.Fragment} {...menuTransitionClasses}>
        <Menu.Items
          className={classnames(
            menuItemsClasses,
            "right-0 top-[48px] w-[284px] max-h-[400px] overflow-y-auto"
          )}
        >
          {user?.umbrellaAccounts.map(umbrellaAccount => (
            <Menu.Item
              as="button"
              key={umbrellaAccount.umbrellaAccName}
              onClick={() => handleClickSelectItem(umbrellaAccount)}
              onKeyPress={(e: React.KeyboardEvent) =>
                handleKeyPressSelectItem(umbrellaAccount, e)
              }
            >
              {({ active }) => (
                <div
                  className={classnames(
                    menuItemClasses,
                    "text-left justify-start !items-start",
                    active ||
                      (umbrellaAccount.umbrellaAccName ===
                        selectedUmbrella.umbrellaAccName &&
                        "bg-cream-200")
                  )}
                >
                  {umbrellaAccount.displayName?.includes("Parent") ? (
                    <ParentSchoolIcon
                      className="w-[24px] h-[24px] flex-shrink-0 fill-current"
                      aria-hidden
                    />
                  ) : (
                    <SchoolIcon
                      className="w-[24px] h-[24px] flex-shrink-0 fill-current"
                      aria-hidden
                    />
                  )}
                  {(
                    umbrellaAccount.displayName ??
                    umbrellaAccount.umbrellaAccName
                  ).replace("Parent", "All schools")}
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

interface IButtonProps {
  selectedUmbrella: UmbrellaAccount;
}

const MenuButton: React.FC<IButtonProps> = ({ selectedUmbrella }) => {
  return (
    <Menu.Button
      className={classnames(
        menuButtonClasses,
        "w-full paragraph rounded-r-none flex-col gap-[10px] justify-center"
      )}
    >
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-[8px]">
          <SchoolIcon
            className="w-[24px] h-[24px] flex-shrink-0 fill-current"
            aria-hidden
          />
          <div className="line-clamp-1 max-w-[290px] text-left">
            {selectedUmbrella.displayName ?? selectedUmbrella.umbrellaAccName}
          </div>
        </div>
        <ArrowIcon
          className="w-[24px] h-[24px] flex-shrink-0 fill-current ml-[4px]"
          aria-hidden
        />
      </div>
      <AccountBilling selectedUmbrella={selectedUmbrella} />
    </Menu.Button>
  );
};

const AccountBilling: React.FC<IButtonProps> = ({ selectedUmbrella }) => {
  const { data: hoursUsedRes, loading } = useAuthenticatedFetch(
    "/api/v1/new_stats/hours_used/",
    { umbrellaAccountId: selectedUmbrella.umbrellaAccName },
    true
  );

  const totalHours = selectedUmbrella.timeLimitMinutes / MINUTES_IN_HOUR;
  const usedHours = hoursUsedRes?.hoursUsed ?? 0;
  const remainingHours = totalHours - usedHours;

  if (loading) {
    return (
      <div className="ml-[4px] w-full h-[20px]">
        <Spinner small />
      </div>
    );
  }

  switch (selectedUmbrella.billingMode) {
    case UMBRELLA_BILLING_MODE.unlimited:
      return (
        <div className="flex gap-[4px] items-center w-full">
          <span className="font-bold text-[24px] ml-[4px]">∞</span> Equity plan
        </div>
      );
    case UMBRELLA_BILLING_MODE.prepaid:
      return (
        <div className="flex flex-col w-full gap-[4px]">
          <div className="w-full h-[8px] rounded-[8px] bg-[#DCE2F1]">
            <div
              className="h-full bg-navy-900 rounded-[8px] rounded-r-none"
              style={{
                width: `${(usedHours / totalHours) * 100}%`
              }}
            />
          </div>
          <div className="flex items-center">
            <span className="font-bold">{Math.round(remainingHours)}</span>/
            {totalHours} Hours remaining
          </div>
        </div>
      );
    case UMBRELLA_BILLING_MODE.postpaid:
    default:
      return (
        <div className="flex gap-[4px] items-center w-full ml-[4px]">
          <span className="font-bold">{usedHours}</span> Hours used
        </div>
      );
  }
};
