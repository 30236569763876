import { Menu, Transition } from "@headlessui/react";
import classnames from "classnames";
import { default as NextLink } from "next/link";
import * as React from "react";

import { useRequiredAuthContext } from "~/components/auth/common";
import { PEAR_PRIVACY_URL, PEAR_TERMS_URL } from "~/constants/api";
import ROUTES from "~/constants/routes";

export const menuButtonClasses = classnames(
  "bg-white rounded-[8px] h-full flex items-center gap-[8px] p-[16px] paragraph",
  "hover:bg-cream-300 menuOpenStyle"
);

export const menuTransitionClasses = {
  enter: "transition ease-out duration-100",
  enterFrom: "transform opacity-0 scale-95",
  enterTo: "transform opacity-100 scale-100",
  leave: "transition ease-in duration-75",
  leaveFrom: "transform opacity-100 scale-100",
  leaveTo: "transform opacity-0 scale-95"
};

export const menuItemsClasses =
  "z-[2000] py-[6px] flex flex-col paragraph absolute rounded-[4px] bg-white shadow-default focus:outline-none";

export const menuItemClasses =
  "w-full flex items-center gap-[8px] bg-white py-[6px] px-[16px] hover:bg-cream-200 focus:bg-cream-200 focus-within:bg-cream-200";

const getBGColor = (lastName: string) => {
  const lastInitial = lastName.slice(0, 1).toLowerCase();
  if ("abcdef".includes(lastInitial)) {
    return "#A2D3FF";
  } else if ("ghijkl".includes(lastInitial)) {
    return "#C4951B";
  } else if ("mnopqrs".includes(lastInitial)) {
    return "#1FBE72";
  } else {
    return "#C5F21F";
  }
};

export const UserMenu: React.FC = () => {
  const { user, signOut } = useRequiredAuthContext();

  return (
    <Menu as="div" className="relative h-full">
      <Menu.Button
        className={classnames(
          menuButtonClasses,
          "border-l border-cream-300 rounded-l-none"
        )}
      >
        <div
          style={{ backgroundColor: getBGColor(user.lastName) }}
          className="flex items-center justify-center paragraph font-medium w-[36px] h-[36px] rounded-full flex-shrink-0"
        >
          {`${user.firstName.slice(0, 1)}${user.lastName.slice(0, 1)}`}
        </div>
        <span className="!hidden max-w-0 sm:!line-clamp-1 sm:max-w-[130px]">
          {`${user.firstName} ${user.lastName.slice(0, 1)}.`}
        </span>
      </Menu.Button>

      <Transition as={React.Fragment} {...menuTransitionClasses}>
        <Menu.Items
          className={classnames(
            menuItemsClasses,
            "right-0 top-[82px] w-[280px]"
          )}
        >
          <NextLink href={ROUTES.settings} passHref>
            <Menu.Item as="a">
              {({ active }) => (
                <div
                  className={classnames(
                    menuItemClasses,
                    active && "bg-cream-200"
                  )}
                >
                  Edit Profile
                </div>
              )}
            </Menu.Item>
          </NextLink>
          <div className="my-[6px] py-[6px] border-b border-t border-cream-300">
            <Menu.Item as="a" target="_blank" href={PEAR_PRIVACY_URL}>
              {({ active }) => (
                <div
                  className={classnames(
                    menuItemClasses,
                    active && "bg-cream-200"
                  )}
                >
                  Product Privacy Policy
                </div>
              )}
            </Menu.Item>
            <Menu.Item as="a" target="_blank" href={PEAR_TERMS_URL}>
              {({ active }) => (
                <div
                  className={classnames(
                    menuItemClasses,
                    active && "bg-cream-200"
                  )}
                >
                  Product Terms of Service
                </div>
              )}
            </Menu.Item>
          </div>
          <Menu.Item as="button" onClick={signOut}>
            {({ active }) => (
              <div
                className={classnames(
                  menuItemClasses,
                  active && "bg-cream-200"
                )}
              >
                Sign Out
              </div>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
