import styled from "@emotion/styled";
import Button from "@material-ui/core/Button";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import * as React from "react";

export interface ErrorBoundaryDefaultFallbackProps {
  error: Error;
}

const ErrorBoundaryDefaultFallback: React.FC<ErrorBoundaryDefaultFallbackProps> = ({
  error
}: ErrorBoundaryDefaultFallbackProps): JSX.Element => {
  return (
    <Wrapper>
      <Inner>
        <Typography variant="body2">Something went wrong</Typography>
        <ReloadButton
          color="primary"
          variant="outlined"
          onClick={(): void => {
            location.reload();
          }}
        >
          Reload the page
        </ReloadButton>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            Details
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <pre>{JSON.stringify(error, null, " ")}</pre>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Inner>
    </Wrapper>
  );
};

export default ErrorBoundaryDefaultFallback;

const Wrapper = styled(Paper)`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

const Inner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ReloadButton = styled(Button)`
  margin: 20px 0;
`;
