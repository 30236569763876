import Paper from "@material-ui/core/Paper";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import classNames from "classnames";
import { SnackbarProvider } from "notistack";
import * as React from "react";

import { useRequiredAuthContext } from "~/components/auth/common";
import ErrorBoundary from "~/components/providers/ErrorBoundary";
import { setCachedWeekStart } from "~/utils/cachedWeekStart";
import { useAuthenticatedFetch } from "~/utils/http";

import LayoutHead from "../common/LayoutHead";
import AccountHeader from "./AccountHeader";
import AccountContext from "./AccountLayoutContext";
import AccountSideMenu from "./AccountSideMenu";

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

const LS_MENU_MINIMIZED_KEY = "sideMenuMinimized";

const AccountLayout: React.FC<Props> = ({ children }: Props): JSX.Element => {
  const {
    user: { weekStart }
  } = useRequiredAuthContext();

  const lsSideMenuMinimized = localStorage.getItem(LS_MENU_MINIMIZED_KEY);
  const theme = useTheme();
  const mobileView = useMediaQuery<boolean>(theme.breakpoints.down("xs"));
  const sideMenuMinimizedInit = !["", null].includes(lsSideMenuMinimized)
    ? JSON.parse(lsSideMenuMinimized as string)
    : mobileView;
  const [sideMenuMinimized, setSideMenuMinimized] = React.useState<boolean>(
    sideMenuMinimizedInit
  );

  const subjects = useAuthenticatedFetch("/api/v1/subjects/", {}, true);

  const [filtersDrawerOpen, setFiltersDrawerOpen] = React.useState(false);

  React.useEffect(() => {
    setCachedWeekStart(weekStart);
  }, [weekStart]);

  React.useEffect((): void => {
    if (mobileView) {
      setSideMenuMinimized(mobileView);
      localStorage.setItem(LS_MENU_MINIMIZED_KEY, mobileView.toString());
    }
  }, [mobileView]);

  const handleTriggerViewSideMenu = (): void => {
    const newMinimizedValue = !sideMenuMinimized;
    setSideMenuMinimized(newMinimizedValue);
    localStorage.setItem(LS_MENU_MINIMIZED_KEY, newMinimizedValue.toString());
  };

  const handleToggleFiltersDrawer = (): void => {
    setFiltersDrawerOpen(!filtersDrawerOpen);
  };

  return (
    <div className="flex flex-col w-full min-h-screen bg-cream-100 sm:flex-row">
      <AccountContext.Provider
        value={{
          mobileView,
          sideMenuMinimized,
          filtersDrawerOpen,
          subjects,
          onToggleFiltersDrawer: handleToggleFiltersDrawer,
          onTriggerViewSideMenu: handleTriggerViewSideMenu
        }}
      >
        <LayoutHead />
        <SnackbarProvider
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
        >
          <AccountSideMenu />
          <Paper
            elevation={0}
            className={classNames(
              "flex flex-col bg-cream-100 transition-width ease-out",
              sideMenuMinimized && !mobileView
                ? "sm:w-[calc(100%-81px)]"
                : "sm:w-[calc(100%-272px)]"
            )}
          >
            <AccountHeader />
            <main className="flex flex-col flex-grow mx-[6px] sm:mx-0 pb-[10px] sm:pb-[30px]">
              <ErrorBoundary>{children}</ErrorBoundary>
            </main>
          </Paper>
        </SnackbarProvider>
      </AccountContext.Provider>
    </div>
  );
};

export default AccountLayout;
