export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;

export enum DATE_UNIT {
  day = "day",
  week = "week",
  month = "month",
  year = "year",
  ms = "ms"
}

export const DATE_FORMAT = "MM/DD/YY";
export const DATE_VIEW_FORMAT = "MM/DD/YY | hh:mm A";
export const DATE_VIEW_FORMAT_MOBILE = "MM/DD/YY hh:mm A";
export const DATE_DASH_VIEW_FORMAT = "MM-DD-YY | hh:mm A";
export const MONTH_FORMAT = "MMM YYYY";
export const MONTH_FULL_FORMAT = "MMMM YYYY";
export const DAY_FORMAT = "D";

export enum PERIOD {
  last7Days = "last7Days",
  last30Days = "last30Days",
  last90Days = "last90Days",
  last365Days = "last365Days",
  allTime = "allTime",
  custom = "custom"
}

export const PERIODS = [
  { period: PERIOD.last7Days, title: "Last 7 days" },
  { period: PERIOD.last30Days, title: "Last 30 days" },
  { period: PERIOD.last90Days, title: "Last 90 days" },
  { period: PERIOD.last365Days, title: "Last 365 days" },
  { period: PERIOD.allTime, title: "All Time" },
  { period: PERIOD.custom, title: "Custom" }
];

export const STATIC_VARIANTS: Array<{
  label: string;
  active: boolean;
  period: PERIOD;
}> = [{ label: "Custom", active: false, period: PERIOD.custom }];
