import { useMemo } from "react";

interface UseLinkWithUmbrellaState {
  pathname: string;
  query?: {
    umbrella?: string;
  };
}

export function useLinkWithUmbrella(
  href?: string,
  additionQuery?: Record<string, unknown>
): UseLinkWithUmbrellaState | null {
  return useMemo(() => {
    if (!href) return null;

    return {
      pathname: href,
      query: additionQuery ?? {}
    };
  }, [additionQuery, href]);
}
