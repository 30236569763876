import Drawer from "@material-ui/core/Drawer";
import classNames from "classnames";
import * as React from "react";

import AccountLayoutContext from "~/components/layouts/AccountLayout/AccountLayoutContext";

import AccountAppBar from "./AccountAppBar";
import AccountMenu from "./AccountMenu";
import { AccountMenuChangeSize } from "./AccountMenuChangeSize";
import AccountMenuHeader from "./AccountMenuHeader";
import AccountMenuNeedHelp from "./AccountMenuNeedHelp";

const AccountSideMenu: React.FC = (): JSX.Element => {
  const {
    mobileView,
    sideMenuMinimized,
    onTriggerViewSideMenu
  } = React.useContext(AccountLayoutContext);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (mobileView && sideMenuMinimized && onTriggerViewSideMenu) {
      onTriggerViewSideMenu();
    }
  }, [mobileView, sideMenuMinimized, onTriggerViewSideMenu]);

  const handleOpenMenu = (): void => setOpen(true);

  const handleCloseMenu = (): void => setOpen(false);

  const widthClass =
    !mobileView && (sideMenuMinimized ? "w-[81px]" : "w-[272px]");
  const paperClasses = classNames(
    "bg-cream-100 border-r border-cream-300 overflow-hidden",
    widthClass
  );

  return (
    <div className="group">
      {!mobileView && <AccountMenuChangeSize />}
      <div className="relative">
        <AccountAppBar onClick={handleOpenMenu} />
        <Drawer
          className={classNames("transition-width ease-out", widthClass)}
          classes={{
            paper: paperClasses
          }}
          variant={mobileView ? "temporary" : "persistent"}
          open={mobileView ? open : true}
          onClose={handleCloseMenu}
          PaperProps={{ elevation: 0 }}
          transitionDuration={225}
        >
          <AccountMenuHeader />
          <AccountMenu />
          <AccountMenuNeedHelp />
        </Drawer>
      </div>
    </div>
  );
};

export default AccountSideMenu;
